import * as Yup from 'yup';
import { PowerOfAttorneyFormValues } from '#/types/powerOfAttorney';

interface ExtendedPowerOfAttorneyFormValues extends PowerOfAttorneyFormValues {
  children_power_of_attorney: any;
  assets_power_of_attorney: any;
}
export const schemaValues = (
  dbFormValues?: ExtendedPowerOfAttorneyFormValues,
  activeStep?: number
) => ({
  trusted_person: dbFormValues?.trusted_person || '',
  compensation: dbFormValues?.compensation || '',
  alternative_person: dbFormValues?.alternative_person || '',
  alternative_compensation: dbFormValues?.alternative_compensation || '',
  has_children: dbFormValues?.children_power_of_attorney?.has_children || '',
  trusted_person_role_for_children:
    dbFormValues?.children_power_of_attorney
      ?.trusted_person_role_for_children || '',
  trusted_person_for_children:
    dbFormValues?.children_power_of_attorney?.trusted_person_for_children || '',
  children_compensation:
    dbFormValues?.children_power_of_attorney?.children_compensation || '',
  alternative_person_for_children:
    dbFormValues?.children_power_of_attorney?.alternative_person_for_children ||
    '',
  alternative_compesation_for_children:
    dbFormValues?.children_power_of_attorney
      ?.alternative_compesation_for_children || '',
  assets_trusted_person:
    dbFormValues?.assets_power_of_attorney?.assets_trusted_person || '',
  assets_compensation:
    dbFormValues?.assets_power_of_attorney?.assets_compensation || '',
  alternative_assets_person:
    dbFormValues?.assets_power_of_attorney?.alternative_assets_person || '',
  alternative_assets_compensation:
    dbFormValues?.assets_power_of_attorney?.alternative_assets_compensation ||
    '',
  step: activeStep,
});

export const powerOfAttorneySchema = Yup.object().shape({
  trusted_person: Yup.string().required('validations.selectAnOption'),
  compensation: Yup.string().required('validations.selectAnOption'),
  alternative_person: Yup.string().required('validations.selectAnOption'),
  alternative_compensation: Yup.string().required('validations.selectAnOption'),
  has_children: Yup.string().required('validations.selectAnOption'),
  trusted_person_role_for_children: Yup.string().when('has_children', {
    is: 'yes',
    then: (schema) => schema.required('validations.selectAnOption'),
    otherwise: (schema) => schema.notRequired(),
  }),
  trusted_person_for_children: Yup.string().when('has_children', {
    is: 'yes',
    then: (schema) => schema.required('validations.selectAnOption'),
    otherwise: (schema) => schema.notRequired(),
  }),
  children_compensation: Yup.string().when('has_children', {
    is: 'yes',
    then: (schema) => schema.required('validations.selectAnOption'),
    otherwise: (schema) => schema.notRequired(),
  }),
  alternative_person_for_children: Yup.string().when('has_children', {
    is: 'yes',
    then: (schema) =>
      schema.required('Please make sure to save or select a person'),
    otherwise: (schema) => schema.notRequired(),
  }),
  alternative_compesation_for_children: Yup.string().when('has_children', {
    is: 'yes',
    then: (schema) => schema.required('validations.selectAnOption'),
    otherwise: (schema) => schema.notRequired(),
  }),
  assets_trusted_person: Yup.string().required('validations.selectAnOption'),
  assets_compensation: Yup.string().required('validations.selectAnOption'),
  alternative_assets_person: Yup.string().required(
    'validations.selectAnOption'
  ),
  alternative_assets_compensation: Yup.string().required(
    'validations.selectAnOption'
  ),
});

export const allValuesReset = {
  trusted_person: null,
  compensation: null,
  alternative_person: null,
  alternative_compensation: null,
  has_children: null,
  trusted_person_role_for_children: null,
  trusted_person_for_children: null,
  children_compensation: null,
  alternative_person_for_children: null,
  alternative_compesation_for_children: null,
  assets_trusted_person: null,
  assets_compensation: null,
  alternative_assets_person: null,
  alternative_assets_compensation: null,
};
